import React from 'react'

import './AppHeader.scss'
import title from './title.svg'

export default function AppHeader () {
  return (
    <div className='AppHeader'>
      <div className='content'>
        <span className='subtitle'>
          Cursillos de evangelización de
        </span>
        <span className='title'>
          <img src={title} alt=''/>
        </span>
        <span className='description'>
          Toda auténtica vida de fe nace de un encuentro con la persona de 
          Jesucristo. Quienes experimentan el Cursillo con apertura de corazón,
          regresan a sus vidas habiendo tocado la gracia de Dios, firmes en la
          fe, y afianzados en la vida de sus parroquias. Tras el Cursillo,
          quienes lo desean pueden pasar a formar parte de nuestro movimiento
          y disfrutar de sus medios para la perseverancia.
        </span>
      </div>
    </div>
  )
}
