import React from 'react'
import AppHeader from './AppHeader/AppHeader'
import Cursillos from './Cursillos'
import AppFooter from './AppFooter'

import './App.scss'
import background from './background.jpg'

function App() {
  return (
    <div className="App" style={{ backgroundImage: `url(${background})` }}>
      <div className="scroll">
        <AppHeader/>
        <Cursillos/>
      </div>
      <AppFooter/>
    </div>
  );
}

export default App;
