import React, { useEffect, useState } from 'react'
import './Cursillos.scss'
import Cursillo, { CursilloData, CursilloJson } from './Cursillo'


export default function Cursillos () {
  const [cursillos, setCursillos] = useState<CursilloData[]>([])
  const now = new Date()

  useEffect(() => {
    async function fetchCursillos() {
      const data = await fetch('cursillos.json')
      const cursillos: CursilloJson[] = await data.json()
      setCursillos(cursillos.map((c) => ({
        ...c,
        inicio: new Date(c.inicio),
        fin: new Date(c.fin)
      })))
    }

    fetchCursillos()
      .catch(console.error)
  }, [])

  return (
    <div className='Cursillos'>
      <div className='header'>
        Próximos cursillos:
      </div>
      {cursillos
        .filter((c) => c.fin >= now)
        .sort((c1, c2) => c1.inicio.getTime() - c2.inicio.getTime())
        .map((c, i) => 
        <Cursillo key={i} cursillo={c} />
      )}
    </div>
  )
}
