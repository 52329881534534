import React from 'react'

import './AppFooter.scss'

export default function AppFooter () {
  return (
    <div className='AppFooter'>
      <div className='content contact'>
        <span className='description'>Para cualquier otra consulta, escríbenos a:</span>
        <span className='email'>info@fieleslaicos.com</span>
      </div>
      <div className='content web'>
        <span className='description'>Para más información puedes visitar:</span>
        <a className='email' href="https://fieleslaicos.com">fieleslaicos.com</a>
      </div>
    </div>
  )
}
