import React from 'react'
import moment, { Moment } from 'moment'

import './Cursillo.scss'

export interface CursilloJson {
  inicio: string
  fin: string
  lugar: string
  seccion: string
  direccion: string
  contacto?: string
}

export interface CursilloData extends Omit<CursilloJson, 'inicio' | 'fin'> {
  inicio: Date
  fin: Date
}

export interface CursilloParams {
  cursillo: CursilloData
}

function date (from: Date, to: Date): string {

  let mCurr = moment(from)
  let dayCurr, monthCurr
  const mto = moment(to)
  const dayTo = mto.date()
  const monthTo = mto.get('month')

  const dates: Moment[] = []

  do {
    dates.push(mCurr)
    dayCurr = mCurr.date()
    monthCurr = mCurr.get('month')

    mCurr = moment(mCurr).add(1, 'day')
  } while(dayCurr !== dayTo || monthCurr !== monthTo)

  return dates
    .reduce((prev, date) => {
      const monthNumber = date.get('month')
      let month = prev[monthNumber]
      if (month === undefined) {
        month = {
          days: [],
          name: date.format('MMMM')
        }

        prev[monthNumber] = month
      }
      month.days.push(date.date())

      return prev
    }, [] as any[])
    .filter((v) => v !== undefined)
    .map((month) => {
      return `${month.days.join(', ')} de ${month.name}`
    })
    .join(', ')
}

export default function Cursillo(params: CursilloParams) {
  const { cursillo }= params

  return <div className='Cursillo'>
    <div className='info'>
      <div className='date'>
        <span>{date(cursillo.inicio, cursillo.fin)}</span>
      </div>
      <div className='location'>
        <span className='section'>{cursillo.seccion}</span>
        <span className='separator-1'/>
        <span className='place'>{cursillo.lugar}</span>
        <span className='separator-2'/>
        <span className='direction'>{cursillo.direccion}</span>
      </div>
    </div>
    <div className='btns'>
      {cursillo.contacto === undefined ?
        <span className='no-contact'>El contacto aún no está disponible</span> :
        <a className='btn' href={`mailto:${cursillo.contacto}`}>Contactar</a>
      }
    </div>
  </div>
}
